<template>
<n-alert
    v-if="marketplace.localization?.['marketplace/signals/avail/info']"
    class="rounded-md" type="warning" size="small">
    <div
        class="notes text-[10px]"
        v-html="marketplace.localization?.['marketplace/signals/avail/info']">
    </div>
</n-alert>

<div v-if="!init" class="flex h-full justify-center items-center py-20">
    <n-spin size="small" />
</div>
<super-table
    v-else
    showSearch
    :filters="filters"
    :actions="actions"
    :pager="dataRef.pager"
    :refFilters="refFilters"
    :loading="loadings.table"
    :columns="dataRef.columns"
    :records="dataRef.records"
    :filtersChanged="filtersChanged"
    type="marketplace.avail.signals"
    columnsStorageName="__columns_marketplace-avail-signals"
    @getData="getData"
    @doSomething="doSomething"
    @applyFilters="changeFilter"
    @reset-filters="resetFilters">
    <template #tableTitle>
        <div class="text-sm text-left mb-2">
            {{ marketplace.localization?.['marketplace/signals/avail/grid/title'] }}
            <span class="text-main font-semibold text-md">{{ dataRef.stats.total }}</span>
        </div>
    </template>
</super-table>

<n-drawer
    :auto-focus="false"
    v-model:show="showInfoModal"
    width="100%"
    class="bg-gray-100 dark:bg-darkbg">
    <n-drawer-content body-content-style="padding: 0 12px;" :auto-focus="false">
        <div class="flex flex-col h-full">
            <div class="relative w-full bg-gray-100 dark:bg-darkbg text-sm text-center text-gray-600 dark:text-white/75 py-2">
                <n-icon
                    size="20"
                    class="text-gray-600 dark:text-white/75 absolute top-1/2 left-0 -translate-y-1/2"
                    @click="showInfoModal = false">
                    <ArrowLeft12Regular />
                </n-icon>
                <div class="flex items-center justify-center text-xs">
                    <strong class="text-main">"{{ signalsDetails.name }}"</strong>&nbsp;
                    <span>{{ marketplace.localization?.['marketplace/signals/avail/dialog/cost'] }} {{ signalsDetails.price_amount }}</span>
                    <rb-coin class="ml-2 w-4" :coin="currency(signalsDetails._price_symbol)" />
                </div>
            </div>
            <div class="pb-4 flex-grow overflow-y-scroll overscroll-none scrollbar-hide">
              <section>
                  <n-alert class="rounded-md" type="info" size="small">
                      <div
                          class="notes text-[10px]"
                          v-html="signalsDetails.info">
                      </div>
                  </n-alert>

                  <super-table
                      :filters="filtersSignalsDetails"
                      :pager="signalsDetails.pager"
                      :columns="signalsDetails.columns"
                      :records="signalsDetails.records"
                      columnsStorageName="__columns_marketplace-avail-signals-details"
                      @getData="getSignalsDetails"
                      @applyFilters="changeFilterDetails">
                      <template #tableTitle>
                          <div class="text-sm text-left mb-2">
                              {{ marketplace.localization?.['marketplace/signals/details/grid/title'] }}
                              <span class="text-main font-semibold text-md">{{ signalsDetails.stats.total }}</span>
                          </div>
                      </template>
                  </super-table>
              </section>
            </div>

            <div class="w-full sticky bottom-0 z-50 py-2 flex justify-end items-center">
                <div v-if="signalsDetails.subscription_type == 'paid'" class="flex items-center">
                    <rb-checkbox
                        :label="'- ' + marketplace.localization?.['marketplace/mentors/dialogs/license_agreement']"
                        v-model:checked="iAcceptIt" />
                    <n-button
                        strong
                        class="ml-4 rounded-md text-white/90"
                        :disabled="!iAcceptIt"
                        :color="gl.mainColor"
                        @click="onBuySignal(signalsDetails.id)">
                        {{ marketplace.localization?.['marketplace/signals/avail/grid/actions/buy'] }}
                    </n-button>
                </div>

                <n-button
                    v-else
                    strong
                    class="rounded-md text-white/90 w-full"
                    :color="gl.mainColor"
                    @click="onBuySignal(signalsDetails.id)">
                    {{ marketplace.localization?.['marketplace/signals/avail/grid/actions/trial'] }}
                </n-button>
            </div>
        </div>
    </n-drawer-content>
</n-drawer>
</template>

<script>
// general
import general from './general';

// icons
import { ArrowLeft12Regular } from '@vicons/fluent';

// components
import RbCoin from '@components/rb-coin';
import RbCheckbox from '@components/rb-checkbox/mobile.vue';
import BaseModal from '@components/base/base-modal';
import SuperTable from '@components/super-table/mobile.vue';
import FilterExchanges from '@components/filters/exchanges';
import FilterButtonGroup from '@components/filters/button-group.vue';

// naive ui
import {
    NSpin,
    NIcon,
    NCard,
    NAlert,
    NButton,
    NDrawer,
    NDrawerContent } from 'naive-ui';

export default {
    name: 'avail-signals-mobile',
    components: {
        NSpin,
        NCard,
        NIcon,
        RbCoin,
        NAlert,
        NDrawer,
        NButton,
        BaseModal,
        SuperTable,
        RbCheckbox,
        NDrawerContent,
        FilterExchanges,
        FilterButtonGroup,
        ArrowLeft12Regular,
    },
    setup(props, context) {
        
        return {
            ...general(...arguments),
        };
    },
};
</script>